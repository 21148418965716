/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _get from 'lodash/get';

import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title, image, path }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
    );
    const pageTitle = title || site.siteMetadata.title;
    const metaDescription = description || site.siteMetadata.description;

    const url = site.siteMetadata.siteUrl;
    const ogImage = `${url}${_get(image, 'publicURL') || '/static/img/null.png'}`;
    const canonical = path ? site.siteMetadata.siteUrl + path : site.siteMetadata.siteUrl;

    return (
        <Helmet
            htmlAttributes={{
                lang: lang || 'it',
            }}
            title={pageTitle}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            link={[
                {
                    rel: 'canonical',
                    href: canonical,
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: ogImage,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:image:src`,
                    content: ogImage,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}
        />
    );
}

export const query = graphql`
  fragment SeoFragment on Frontmatter {
      pageTitle
      metaDescription
      metaKeywords
      socialPreviewImage{
        publicURL
      }
  }
`;

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO;